import styled, { css } from 'styled-components';
import {
    font,
    color,
    hover,
    vw,
    mediaBreakpointUp,
    offset,
    allBreakpointValue,
    svgMaskStyle,
    mediaBreakpointDown,
    adaptiveHardValues,
    anglePositionStyles
} from '@/style/mixins';
import Image from '@/components/common/Image/Image';
import { Colors } from '@/style/colors';
import Tag from '@/components/ui/Tag/Tag';
import { Container as ModalBtn } from '@/components/ui/ModalBtn/ModalBtn.styled';
import { Container as Picture } from '@/components/common/Picture/Picture.styled';
import { Card1ContainerProps } from '@/interfaces';
import variables from '@/style/variables';

const baseBoxShadow = '0px 4px 20px 0px #00000026';

export const SvgMask = styled.div`
    ${svgMaskStyle};

    svg {
        fill: ${color('maskColor1')};
    }
`;

export const Angle = styled.div<{ position: string; fill?: Colors }>`
    display: none;

    ${mediaBreakpointUp('xl')} {
        display: block;
        position: absolute;
        overflow: hidden;
        width: 38px;
        height: 38px;
        user-select: none;

        ${({ position }) => anglePositionStyles(position)};

        svg {
            fill: ${({ fill }) => (fill ? color(fill) : color('Bg-Light'))};
        }
    }
`;

export const Card1More = styled.div<{ background: boolean; textColor?: Colors }>`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    ${offset('l', 'margin-top')};
    background-color: ${({ background }) => (background ? color('blue') : 'none')};

    ${({ background }) =>
        background &&
        css`
            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                border-bottom: solid ${color('blue')};
                border-left: solid transparent;
                ${allBreakpointValue('border-bottom-width', 80, 60, 54, 50, 50)};
                ${allBreakpointValue('border-left-width', 72, 54, 48, 45, 45)};
                transform: translateX(-99%);
            }
        `}

    svg {
        display: block;
        stroke: ${color('link-default')};
        transition: transform 0.3s ease-in-out;
        width: 14px;
        height: 14px;
    }

    span {
        color: ${color('link-default')};
    }

    ${hover(`
        color: ${color('link-hover')};
        svg {
            stroke: ${color('link-hover')};
            transform: translateX(5px);
        }
        span {
            color: ${color('link-hover')};
        }          
    `)};

    ${ModalBtn} {
        color: ${({ textColor }) => (textColor ? color(textColor) : color('white'))};
    }
`;

export const Title2 = styled.div`
    margin-top: 15px;
`;
export const Title = styled.h3<{ VideoCard?: boolean }>`
    ${({ VideoCard }) => VideoCard && 'visibility:hidden;opacity:0;'};

    transition: visibility 0.3s ease-in, opacity 0.3s ease-in;
`;
export const Card1Mask = styled.div<{ colorBg?: Colors }>`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background-color: ${({ colorBg, theme }) => (colorBg ? color('white') : color(theme.main.background))};
    pointer-events: none;
    transition: background-color 0.3s ease-in-out;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        border-bottom-style: solid;
        border-left-style: solid;
        border-bottom-color: transparent;
        border-left-color: ${({ colorBg, theme }) => (colorBg ? color('white') : color(theme.main.background))};
        transform: translateX(99%);
        transition: border-left-color 0.3s ease-in-out;
    }
`;

export const Card1TextBottom = styled.div<{ dateColor?: Colors; withArrow?: boolean }>`
    margin-top: auto;
    transition: color 0.3s ease-in-out;
    ${font('text1-l')};

    color: ${({ dateColor }) => (dateColor ? color(dateColor) : color('black'))};
    opacity: 0.5;

    ${({ withArrow }) =>
        withArrow &&
        `
        opacity: 1;
        display: inline-block;

        svg {
            width: 10px;
            height: 10px;
            stroke: currentColor;
            margin-left: 10px;
            vertical-align: middle;

            ${mediaBreakpointUp('fhd')} {
                width: ${vw(18, 'fhd')};
                height: ${vw(18, 'fhd')};
            }
        }
    `};
`;

export const Container = styled.a<Card1ContainerProps & { type?: string; flippedTags?: boolean }>`
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${offset('m', 'padding')};
    background-color: ${({ bgColor }) => color(bgColor ? bgColor : 'white')};
    color: ${({ textColor }) => (textColor ? color(textColor) : color('text-primary'))};
    border-radius: ${variables.corners.bigCard};
    overflow: hidden;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

    ${({ type }) =>
        type === 'video' &&
        css`
            ${adaptiveHardValues('height', 232, 214, 198)};
            ${Card1Background}, ${Card1TextBottom} {
                transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
                border-radius: ${variables.corners.smallCard};
            }

            ${Card1Mask} {
                display: none;
            }

            ${Card1TextBottom} {
                opacity: 1;
                visibility: visible;
                width: fit-content;
                background-color: ${color('white')};
                color: ${color('text-primary')};
                border-radius: 12px;
                padding: 4px 8px;
                position: absolute;
                box-shadow: ${baseBoxShadow};

                ${offset('xs', 'top')};
                ${offset('xs', 'right')};
                ${font('h3')};

                ${mediaBreakpointUp('xl')} {
                    display: none;
                }

                svg {
                    display: none;
                }
            }

            ${hover(`
                h3 {
                    opacity: 1;
                    visibility: visible;
                }
                ${Card1BackgroundContainer} {
                    ${Angle} {
                        svg {
                            fill: transparent;
                        }
                    }
                }
                ${Card1Background}, ${Card1TextBottom} {
                    opacity: 0;
                    visibility: hidden;
                }
                ${Card1Play} {
                    transform: scale(1.2);
                    box-shadow: none;
                }
            `)}
        `}

    ${({ backgroundImage }) =>
        backgroundImage &&
        `
            background-image: url(${backgroundImage});
            background-color: ${color('blue', 0.9)};
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

    `};

    ${mediaBreakpointUp('xl')} {
        ${({ isHover, textHover, bgHover, maskHover, theme }) =>
            isHover &&
            `
            transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

            ${Card1Mask} {
                transition: background-color 0.3s ease-in-out;

                &::after {
                    transition: border-left-color 0.3s ease-in-out;
                }
            }

            &:hover {
                color: ${textHover ? color(textHover) : color(theme.secondary.text)};
                background-color: ${bgHover ? color(bgHover) : color(theme.hover)};

                }
                h3 {
                    visibility: visible;
                    opacity: 1;
                }
                ${Card1Mask} {
                    background-color: ${maskHover && color(maskHover)} !important;

                    &::after {
                        border-left-color: ${maskHover && color(maskHover)} !important;
                    }
                }

                ${Card1TextBottom} {
                    color: ${color('white', 0.5)};
                }
            }
        `};
    }

    ${({ flippedTags }) =>
        flippedTags &&
        `
        ${Title} {
            margin-bottom: 10px;
        }

        ${Card1TextBottom} {
            margin-top: 0;
            ${allBreakpointValue('margin-bottom', 40, 30, 20, 15, 15)}
        }

        ${Card1Tags} {
            order: 9;
            margin-top: auto;
            margin-right: -5px;
            overflow: hidden;

            ${Card1Tag} {
                white-space: nowrap;
                margin-right: 5px;
            }

            ${mediaBreakpointDown('xs')} {
                max-height: 67px;
                @media(min-width: 370px) {
                    max-height: 75px;
                }
            }
            max-height: 75px;
            ${mediaBreakpointUp('md')} {
                max-height: 75px;
            }
            ${mediaBreakpointUp('xl')} {
                max-height: 35px; // 72
            }
            ${mediaBreakpointUp('xxl')} {
                max-height: 75px;
            }
            ${mediaBreakpointUp('fhd')} {
                max-height: 91px;
            }
        }
    `}

    h3 {
        position: relative;
        z-index: 1;

        ${({ type }) =>
            type === 'video' &&
            css`
                ${font('h3')}
                opacity: 0;
                visibility: hidden;
            `}

        ${({ reverseFonts }) =>
            reverseFonts
                ? css`
                      ${allBreakpointValue('margin-bottom', 16, 12, 10, 24, 28)};
                      ${font('text1-l')};
                  `
                : css`
                      margin-bottom: 16px;
                      ${font('h3')};
                  `};

        ${({ backgroundTitle, fullWidthTitle }) =>
            fullWidthTitle &&
            !backgroundTitle &&
            css`
                margin-right: 0 !important;
            `}
    }

    p {
        ${({ reverseFonts }) => (reverseFonts ? font('h5-b') : font('text2-sb'))};
    }
    p,
    .card1__description {
        flex-grow: 1;
        opacity: 0.8;
    }

    ${Card1Mask} {
        ${({ type }) =>
            type === 'news'
                ? css`
                      ${allBreakpointValue('width', 400, 300, 265, 215, 215)};
                      ${allBreakpointValue('height', 130, 110, 105, 110, 110)};
                  `
                : css`
                      ${allBreakpointValue('width', 375, 280, 250, 220, 220)};
                      ${allBreakpointValue('height', 160, 120, 105, 115, 115)};
                  `}

        &::after {
            ${({ type }) =>
                type === 'news'
                    ? css`
                          ${allBreakpointValue('border-bottom-width', 130, 110, 105, 110, 110)};
                          ${allBreakpointValue('border-left-width', 120, 95, 90, 95, 100)};
                      `
                    : css`
                          ${allBreakpointValue('border-bottom-width', 160, 120, 105, 115, 115)};
                          ${allBreakpointValue('border-left-width', 135, 95, 90, 100, 100)};
                      `}
        }
    }
    ${ModalBtn} {
        margin-right: 0;
        & > p {
            flex-grow: 1;
            opacity: 0.8;
        }
        ${({ fullModalCard }) =>
            fullModalCard
                ? css`
                      position: initial;
                      border: none;
                      height: 100%;
                  `
                : ''}
    }

    &.simple-widget {
        & > h3 {
            margin-right: 0;
        }

        & > p {
            max-width: none;
        }

        ${Card1More} {
            left: 0;
            right: initial;

            padding-left: 14px;

            ${mediaBreakpointUp('xxl')} {
                padding-left: 16px;
            }

            ${mediaBreakpointUp('fhd')} {
                padding-left: 20px;
            }
        }
    }

    ${Picture} {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
    }

    ${({ partitions }) =>
        partitions &&
        css`
            border-radius: 8px;
            div {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: auto;
                color: ${color('white')};
                h3 {
                    margin-bottom: 0px;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -moz-box-orient: vertical;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    line-clamp: 3;
                    ${font('h5-l')}
                    font-weight: 450;
                }
            }

            svg {
                stroke: ${color('white')};
                margin-left: 15px;
                ${allBreakpointValue('width', 13, 9, 8, 8, 7)};
            }
        `};
`;

export const Card1BackgroundContainer = styled.div<{ type?: string }>`
    ${({ type }) =>
        type === 'video' &&
        css`
            position: absolute;
            ${offset('xs', 'inset')};

            ${mediaBreakpointUp('xl')} {
                ${Angle} {
                    width: 24px;
                    height: 24px;

                    svg {
                        transition: fill 0.3s ease-in-out;
                        fill: ${color('Content-02')};
                    }
                }
            }
        `}
`;

export const Card1Background = styled(Image)<{ bg?: Colors }>`
    position: absolute;
    inset: 0;
    z-index: -1;
    pointer-events: none;
    ${({ bg }) => bg && `background-color: ${color(bg)}`};
`;

export const Card1Tag = styled(Tag)`
    position: absolute;
    ${offset('m', 'top')};
    ${offset('m', 'right')};
`;

export const Card1Tags = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: auto;

    ${Card1Tag} {
        position: relative;
        top: auto;
        right: auto;
        margin-top: 5px;

        &:not(:last-of-type) {
            margin-right: 4px;

            ${mediaBreakpointUp('xl')} {
                margin-right: 5px;
            }
        }
    }
`;

export const Card1Play = styled.div`
    position: absolute;
    bottom: 24px;
    right: 24px;
    z-index: 1;
    display: flex;
    box-shadow: ${baseBoxShadow};
    background-color: ${color('Content-01')};
    border-radius: 50%;
    width: 48px;
    height: 48px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    ${mediaBreakpointUp('xl')} {
        width: 60px;
        height: 60px;
        bottom: 28px;
        right: 28px;
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        border-top: solid transparent;
        border-bottom: solid transparent;
        border-left: solid ${color('Content-04')};
        ${adaptiveHardValues('border-top-width', 6.5, 5.5, 5.5)};
        ${adaptiveHardValues('border-bottom-width', 6.5, 5.5, 5.5)};
        ${adaptiveHardValues('border-left-width', 11, 9, 9)};
        transform: translate(-50%, -50%);
    }
`;

export const Card1Icons = styled.div`
    margin-top: auto;
    ${offset('xxs', 'padding-top')};
    display: flex;
`;
export const Card1FileName = styled.div`
    margin-top: auto;
    ${font('text2-l')};
    ${offset('xxs', 'padding-top')};
    display: flex;
`;

export const Card1Icon = styled.div`
    &:not(:first-child) {
        ${offset('xxs', 'margin-left')};
    }

    svg {
        width: 100%;
        height: 100%;
        fill: currentColor;
    }
`;

export const Partition = styled.div`
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 22px;
    padding-right: 22px;
    background-color: ${color('blue')};
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: ${color('white')};
`;

export const Card1Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;

    ${Card1TextBottom} {
        flex-shrink: 0;
        margin-top: 0;
    }

    ${Card1Tags} {
        flex-direction: row-reverse;

        ${Card1Tag} {
            margin-right: 0;
            margin-left: 5px;
        }
    }
`;
